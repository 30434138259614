<template>
  <div>
    <!-- 动态详情 -->
    <div class="container">
      <div class="content">
        <!-- 标题 -->
        <div class="content-header">
          <div class="content-title">大赛动态</div>
        </div>

        <!-- 动态内容 -->
        <div class="news-content">
          <!-- 标题 -->
          <div class="title">
            {{ newsInfo.title }}
          </div>

          <!-- 发布时间 -->
          <div class="create-time">
            发布时间 ：{{ newsInfo.createTime }}
          </div>

          <div class="content-text">
            <!--            {{ newsInfo.content }}-->
            <div v-html="newsInfo.content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { retrieveNews } from '@/api/homepage'

export default {
  data() {
    return {
      newsId: '',

      newsInfo: {
        title: '',
        createTime: '',
        content: ''
      }
    }
  },
  mounted() {
    this.newsId = this.$route.params.id
    console.log('newsid ' + this.newsId)
    this.fetchNews(this.newsId)
  },
  methods: {
    fetchNews(id) {
      retrieveNews(id).then(res => {
        this.newsInfo = res.data
      })
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.container {
  width: 100%;
  background-color: var(--background-light-color);
  min-height: 50vh;
  /* border: 1px solid red; */
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .container {
    background-color: var(--background-color);
  }
}

.content {
  /* display: table-cell; */
  display: inline-block;
  margin: auto;
  margin-top: 30px;
  width: 70vw;
  background-color: #fff;
  /* border-radius: 10px; */
  padding-bottom: 70px;
  margin-bottom: 200px;
  border-radius: 10px;

  /* border: 1px solid red; */
}

@media screen and (max-width: 1200px) {
  .content {
    display: inline-block;
    width: 330px;
    margin: auto;
    /* min-height: 130px; */
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.content-header {
  width: 90%;
  margin: auto;
  font-size: 18px;
  height: 60px;
  text-align: left;
  padding-top: 25px;
  border-bottom: 1px solid rgb(179, 175, 175);

}

.content-title {
  /* position: absolute;
  top: 30px; */
  text-align: 60px;
  width: 95%;
  margin: auto;
  padding-left: 7px;
}

/* 内容部分 */
.news-content {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  /* height: 300px; */
  text-align: center;
  /* border: 1px solid red; */
}

.title {
  font-size: 22px;
  font-weight: 800;
  color: #1a1919;
}

.create-time {
  margin-top: 25px;
  font-size: 14px;
  color: #929191;
}

.content-text {
  margin-top: 25px;
  text-align: left;
  font-size: 17px;
  line-height: 35px;
  color: rgb(100, 98, 98);
}

.content-text /deep/ p img {
  max-width: 80% !important;
  /* width: auto; */
  height: auto;
}

</style>
